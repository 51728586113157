@import 'primeflex/primeflex.css';  
@import 'primereact/resources/primereact.css';
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primeicons/primeicons.css';

@import "./_tabs.scss";
@import "./_variables.scss";

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
      url("../fonts/FontsFree-Net-ProductSans-Black.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"),
      url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"),
      url("../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
.main-layout{
  background-color: #FAFBFF;
}

.highcharts-axis-labels highcharts-coloraxis-labels{
display: none;
}
.highcharts-legend-item highcharts-undefined-series highcharts-color-undefined{
    display: none;
}
.highcharts-legend highcharts-no-tooltip{
    display: none;
}
.active-column {
    border: 2px solid black; 
  }
  .p-menuitem-content{
    color: white;
  }
  .highcharts-credits{
    display: none;
  }
  .highcharts-legend {
    display: none;
   }
   .map-grids {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  
.p-timeline .p-timeline-event-marker {
  border: 2px solid #1a1a1a;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #1a1a1a;
}
.history-timeline-content {
  .timeline-heading {
      // color:#2a4c1c;
      font-weight: 400;
      font-size: 16px;
  }
  .timeline-heading2 {
    color:#1C254C;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Product Sans';
}
margin-top: -5px;
  .timeline-desc {
      // color:#696969;

  }
}
.p-timeline-left .p-timeline-event-opposite {
  text-align: right;
  display: none;
}
.p-timeline .p-timeline-event-connector {
  background-color: #1C254C;
}
.map-icon{
  .pi {
    font-family: primeicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    background-color: #1C254C;
    padding: 5px;
    color: white;
    border-radius: 4px;
}
}

hr {
  border: 1px solid #0000001A;
}
.map-color{
  color: #1C254C;
}
.backlogcolor{
  color: #2C2C2C;
}
.newtimeline-text-color{
  color: #696969;
  font-weight: 400;
    font-size: 12px;
    font-family: 'Product Sans';
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #1c254c;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #1c254c;
  background:#1c254c;
} .p-radiobutton-box.p-highlight .p-radiobutton-icon{
  background: none;
 }
 
 .p-radiobutton.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(1, 1);
visibility: hidden;
}

.primary-button {
  border-radius: 24px;
  background: var(--primary-color);
  color: var(--white-color);
  text-align: center !important;
  height: 40px;
  border: transparent;
  min-width: 120px;
  font-weight: 400;
  font-size: 15px !important;
}
.secondary-button {
  border-radius: 24px;
  background: var(--white-color);
  color: var(--secondary-menu-color);
  text-align: center !important;
  height: 40px;
  border: 1px solid var(--secondary-menu-color);
  font-weight: 400;
  font-size: 15px !important;
  min-width: 120px;
}
.tertiary-button {
  border-radius: 24px;
  background: var(--white-color);
  color: var(--text-dim-color);
  text-align: center !important;
  height: 41.8px;
  border: 1px solid var(--text-dim-color);
  font-weight: 400;
  font-size: 15px !important;
  min-width: 120px;
}
.rejection-button {
    border-radius: 24px;
    background: #E40615;
    color: var(--white-color);
    text-align: center !important;
    height: 40px;
    border: transparent;
    min-width: 120px;
    font-weight: 400;
    font-size: 15px !important;
}
.secondary-status-button {
  border-radius: 24px;
  background: var(--secondary-menu-color);
  color: var(--white-color);
  text-align: center !important;
  height: 40px;
  border: transparent;
  min-width: 120px;
  font-weight: 400;
  font-size: 15px !important;
}
.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: #37488E;
}

.icon-button {
  height: 40px;
  width: 42px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  background-color: #FFF;
}
.primary-text{
  color:#2C2C2C;
  font-weight: 500;
  font-size: 24px;
  font-family: 'Product Sans';
}
.star-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.star {
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  background-color: red;
  clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
  );
}

.star::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: red;
  clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
  );
  transform: rotate(36deg);
}


.number {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.timeline-demo {
  // max-height: 250px;
  // height: calc(100vh - 360px);
  overflow-y: auto;
}

.timeline-demo .history-timeline-content.first .timeline-heading,
.timeline-demo .history-timeline-content.first .timeline-desc,
.timeline-demo .history-timeline-content.first::before {
  color: #107E5F;
}

.timeline-demo .history-timeline-content.second .timeline-heading,
.timeline-demo .history-timeline-content.second .timeline-desc,
.timeline-demo .history-timeline-content.second::before {
  color: #238EF1;
}
.timeline-demo .history-timeline-content.third .timeline-heading,
.timeline-demo .history-timeline-content.third .timeline-desc,
.timeline-demo .history-timeline-content.third::before {
  color: #FFC300;
}
.timeline-demo .history-timeline-content.fourth .timeline-heading,
.timeline-demo .history-timeline-content.fourth .timeline-desc,
.timeline-demo .history-timeline-content.fourth::before {
  color: #FF8227;
}
.timeline-demo .history-timeline-content.fifth .timeline-heading,
.timeline-demo .history-timeline-content.fifth .timeline-desc,
.timeline-demo .history-timeline-content.fifth::before {
  color: #FF2626;
}
.p-timeline .p-timeline-event-marker.first {
  border-color: #107E5F;
  background-color: #107E5F;
}

.p-timeline .p-timeline-event-marker.second {
  border-color: #238EF1;
  background-color: #238EF1;
}
.p-timeline .p-timeline-event-marker.third {
  border-color: #FFC300;
  background-color: #FFC300;
}.p-timeline .p-timeline-event-marker.fourth {
  border-color: #FF8227;
  background-color: #FF8227;
}
.p-timeline .p-timeline-event-marker.fifth {
  border-color: #FF2626;
  background-color: #FF2626;
}
.p-timeline .p-timeline-event-marker.default {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}

//timeline custom scrolbar
.timeline-demo::-webkit-scrollbar {
  width: 4px;
}

.timeline-demo::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.timeline-demo::-webkit-scrollbar-thumb {
  background: #1C254C;
  border-radius: 4px;
}

.timeline-demo::-webkit-scrollbar-thumb:hover {
  background: #1C254C;
}
.custom-border {
  border: 2px solid #E4E4E7;
}
.bg-header {
  background: var(--primary-color);
}
.notification-badge {
  position: relative;
  left: -19px;
  top: -14px;
}
.top-bar-dopdown-position {
  right: 0px;
    top: 50px;
    min-width: 148px;
}
.option-menu {
  border: 1px solid #e1e0e0;
  padding: 10px;
  border-radius: 8px;
}
.primary-text2 {
  color:#2c2c2c;
  font-weight: 400;
  font-size: 14px;
}
.top-bar-dopdown-position2 {
  // left: 15px;
  right:10px;
    top: 50px;
    min-width: 99px;
}
.notification-overlay:before {
  display: none;
}

.notification-overlay:after {
  // margin-left: -18px;
  display: none;
}
.notification-overlay {
  border-radius: 16px;
  box-shadow: 0px 10px 20px 0px #0000001A;
  width: 325px;
  margin-top: 17px;

  // margin-left: 15px;

  .p-overlaypanel-content {
      padding: 0%;

      .notification-header {
          font-family:'Product Sans' ;
          padding: 1rem;
          color: #1c254c !important;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          border-bottom: 1px solid black;
      }

      .mark-as-read {
          position: absolute;
          top: 70px;
          right: 20px;
          z-index: 1;
      }
      .p-tabview .p-tabview-panels{
        min-height: 30vh;
    }
      .notification-tabview {
          .p-tabview-nav {
              padding: 0px 5px !important;
              border: none !important;

              .p-highlight {
                  .p-tabview-nav-link {
                      border-width: 0 0 5px 0 !important;
                  }
              }

              .p-tabview-nav-link {
                  padding: 1rem 1rem 0.5rem 1rem;
              }
          }

          .p-tabview-panels {
              padding: 0px !important;
              border: none !important;
          }
      }

      .notification-body {
          height: calc(100vh - 415px);
          .unread {
            background-color: #f3f4f6;

            hr {
                color: #E9E9E9;
                border: 1px solid #E9E9E9;
                padding: 0px !important;
                margin: 0px !important;
            }
        }

          .notif-message{
         font-family: 'Lato';
           font-weight: 700;
           font-size: 14px;
           color:#000000 ; 

          }
          
          .notif-time{
            font-family: 'Lato';
           font-weight: 400;
           font-size: 12px;
           color:#000000 ; 

          }

              hr {
                  color: #E9E9E9;
                  border: 1px solid #E9E9E9;
                  padding: 0px !important;
                  margin: 0px !important;
              }
          

          .read {
              background-color: none;

              hr {
                  color: #E9E9E9;
                  border: 1px solid #E9E9E9;
                  padding: 0px !important;
                  margin: 0px !important;
              }
          }
      }
  }
}
.profile-flag{
  font-family: 'Product Sans';
  font-size: 14px;
  font-family: 400;
}
.percent-font{
  font-size: 24px;
  font-weight: 700;
  font-family: 'Product Sans';
}
.top-title-font{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Product Sans';
}
.weather-time{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Product Sans';
  color:#2C2C2C ;
  line-height: 20px;
}
.weather-tempearature{
  font-size: 16px;
  font-weight: 700;
  font-family: 'Product Sans';
  color:#2C2C2C ;
  line-height: 20px;
}
.waethertitle{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Product Sans';
  color:#2C2C2C ;
  line-height: 20px;
}
.waethertitle-temperature{
  font-size: 22px;
  font-weight: 700;
  font-family: 'Product Sans';
  color:#37488E ;
  line-height: 26px;
}
.open-title{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Product Sans';
  line-height: 20px;
  color:#107E5F
}
.closed-title{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Product Sans';
  line-height: 20px;
  color:#F50000
}
.modal-title{
  font-size: 20px;
  font-family: 'Product Sans';
  font-weight: 400;
  color: var(--primary-color-text);
  line-height: 24.26px;
}

//dropdown custom scroll bar 
.p-dropdown-items-wrapper{
  margin-right: 4px;
  }
  .p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 5px;
  }
  
  .p-dropdown-items-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    background: #37488E;
    border-radius: 4px;
  }
  
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb:hover {
    background: #37488E;
  }

  //status form custom select button
.operation-selected-button{
  .p-selectbutton {
    display: flex; 
    gap: 10px;
  }
  .p-selectbutton .p-button {
    background-color: #FFFFFF !important; 
    border: 1px solid #DFDFDF !important; 
    border-radius: 5px; 
    width: 66px;
    height: 42px; 
    display: inline-flex; 
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    color: inherit !important; 
    font-size: 12px;
    font-weight: 700;
    font-family: 'Product Sans';
    box-shadow: 0px 4px 5px 0px #0000000D;
    vertical-align: middle; 
  }

.option-open {
  color: #107E5F;
}

.option-limited {
  color: #238EF1;
}

.option-closed {
  color: #FF2626;
}
.option-100 {
  color: #107E5F;
  font-size: 16px;
}

.option-75 {
  color: #238EF1;
  font-size: 16px;
}
.option-50 {
  color: #FFC300;
  font-size: 16px;
}
.option-25 {
  color: #FF8227;
  font-size: 16px;
}
.option-0 {
  color: #FF2626;
  font-size: 16px;
}
.p-selectbutton .p-button.p-highlight {
  background-color: #F2F3FC !important;
  border: 2px solid transparent !important; 
  box-shadow: none !important; 
}

.p-selectbutton .p-button.p-highlight.option-open {
  border: 2px solid #107E5F !important;
  background-color: #107E5F33 !important;
}

.p-selectbutton .p-button.p-highlight.option-limited {
  border: 2px solid #238EF1 !important;
  background-color: #238EF133 !important;
}

.p-selectbutton .p-button.p-highlight.option-closed {
  border: 2px solid #FF2626 !important;
  background-color: #FF262633 !important;
}
.p-selectbutton .p-button.p-highlight.option-100 {
  border: 2px solid #107E5F !important;
  background-color: #107E5F33 !important;
}

.p-selectbutton .p-button.p-highlight.option-75 {
  border: 2px solid #238EF1 !important;
  background-color: #238EF133 !important;
}

.p-selectbutton .p-button.p-highlight.option-0 {
  border: 2px solid #FF2626 !important;
  background-color: #FF262633 !important;
}
.p-selectbutton .p-button.p-highlight.option-50 {
  border: 2px solid #FFC300 !important;
  background-color: #FFC30033 !important;
}.p-selectbutton .p-button.p-highlight.option-25 {
  border: 2px solid #FF8227 !important;
  background-color: #FF822733 !important;
}
}
.dock-selected-button{
  .p-selectbutton {
    display: flex; 
    gap: 10px;
  }
  .p-selectbutton .p-button {
    background-color: #FFFFFF !important; 
    border: 1px solid #DFDFDF !important; 
    border-radius: 5px; 
    width: 66px;
    height: 42px; 
    display: inline-flex; 
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    color: inherit !important; 
    font-size: 12px;
    font-weight: 700;
    font-family: 'Product Sans';
    box-shadow: 0px 4px 5px 0px #0000000D;
    vertical-align: middle; 
  }
  
  .option-normal {
    color: #107E5F;
  }
  
  .option-crowded {
    color: #238EF1;
  }
  
  .option-overcrowded {
    color: #FF2626;
  }
  .option-closed {
    color: #FF2626;
  }
  .option-100 {
    color: #107E5F;
    font-size: 16px;
  }
  
  .option-75 {
    color: #238EF1;
    font-size: 16px;
  }
  .option-50 {
    color: #FFC300;
    font-size: 16px;
  }
  .option-25 {
    color: #FF8227;
    font-size: 16px;
  }
  .option-0 {
    color: #FF2626;
    font-size: 16px;
  }
  .p-selectbutton .p-button.p-highlight {
    background-color: #F2F3FC !important;
    border: 2px solid transparent !important; 
    box-shadow: none !important; 
  }
  
  .p-selectbutton .p-button.p-highlight.option-normal {
    border: 2px solid #107E5F !important;
    background-color: #107E5F33 !important;
  }
  
  .p-selectbutton .p-button.p-highlight.option-crowded {
    border: 2px solid #238EF1 !important;
    background-color: #238EF133 !important;
  
  }
  
  .p-selectbutton .p-button.p-highlight.option-overcrowded {
    border: 2px solid #FF2626 !important;
    background-color: #FF262633 !important;
  }
  .p-selectbutton .p-button.p-highlight.option-100 {
    border: 2px solid #107E5F !important;
    background-color: #107E5F33 !important;
  }
  
  .p-selectbutton .p-button.p-highlight.option-75 {
    border: 2px solid #238EF1 !important;
    background-color: #238EF133 !important;
  }
  
  .p-selectbutton .p-button.p-highlight.option-0 {
    border: 2px solid #FF2626 !important;
    background-color: #FF262633 !important;
  }
  .p-selectbutton .p-button.p-highlight.option-50 {
    border: 2px solid #FFC300 !important;
    background-color: #FFC30033 !important;
  }.p-selectbutton .p-button.p-highlight.option-25 {
    border: 2px solid #FF8227 !important;
    background-color: #FF822733 !important;
  }
}


.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--white-color);
  background: var(--secondary-menu-color);
  margin-left: 2px;
  margin-right: 6px;
  border-radius: 6px;
}
.character-count {
  color: #696969; 
  font-size: 10px; 
  font-family: 'Product Sans';
  font-weight: 400;
  display: flex; 
  justify-content: space-between;
}


// status form slider css

.slider-container {
  position: relative;
  width: 100%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.slider-label.start {
  position: absolute;
  left: -19px;
  text-align: center;
}

.slider-label.end {
  position: absolute;
  right: -22px;
  text-align: center;
}

.slider-value {
  position: absolute;
  // top: 10px; 
  transform: translateX(-50%);
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Product Sans';
  color: #37488E;
}
.p-slider {
  background: #696969;
  border: 0 none;
  border-radius: 6px;
}
.p-slider.p-slider-horizontal {
  height: 0.1rem;
}
.p-slider .p-slider-handle {
  border: 2px solid #37488E; 
  background-color:#37488E ;
}
.p-slider .p-slider-range {
  background: #37488E;
  height: 0.2rem;
}
.statusform-label{
font-size: 16px;
font-weight: 400;
font-family: 'Product Sans';
color: #2C2C2C;
line-height: 22.4px;
}
.slider-text{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Product Sans';
  color: #000000;
  }
  .status-form{
    .p-dropdown-label{
font-size: 16px;
font-weight: 400;
font-family: 'Product Sans';
color:#696969;
    }
    .p-inputtext {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Product Sans';
      color:#696969;
    }
  }
  

// map navigation css

.zoom-out-cont{
    position: absolute;
    top: 38%; 
    right: 5%; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;

    @media screen and (max-width: 1024px) and (max-height: 486.40px) {
      top: 55%;
    }
    
}

.zoom-out-btn{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #E1E0E0;
   /*  text-align: center;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Product Sans';
    cursor: pointer;
    color: #000000; */
    //margin-right:2px;
}

.legend-container{
  position: absolute;
  top: 66%;
  right: calc(6% - 10px); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 116px;
  background-color: white;
  min-height: 118px;
  border: 0.87px solid #E1E0E0;
  border-radius: 6.94px;

  @media screen and (max-width: 1366px) and (max-height: 768px) {
    top: 70%;
  }
  @media screen and (max-width: 1024px) and (max-height: 486.40px) {
    top: 95%;
  }
  .legend{
    border-bottom:0.87px solid #E1E0E0;
    
  }


p{
  margin: 0;
  font-family: 'Product Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 17.35px;
  align-self: baseline;
  
}

.checkbox{
  width: 13.04px;
  height: 13.04px;
  border: 1.04px solid #37488E;
  cursor: pointer;
}

input[type='checkbox'] {
  accent-color: #37488E;
}


}

  .compass-container {
    position: relative;
    display: flex;
  }

  .compass {
    position: absolute;
    top: 45%; 
    //right: 5%; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
  }
  
  .compass-button {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #E1E0E0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Product Sans';
    cursor: pointer;
    color: #000000;
    background-color: white;
  }

  .compass-button:nth-child(1) {
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  .compass-button:nth-child(2) {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
  .compass-button:nth-child(3) {
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }
  
  .compass-button:nth-child(4) {
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);

  }
  
  .compass::before,
  .compass::after {
    content: '';
    position: absolute;
    background-color: #000;
  }
  
  .compass::before {
    width: 1px;
    height: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .compass::after {
    height: 1px;
    width: 25%;
    top: 50%;
    transform: translateY(-50%);
  }
  .compass-button.button-success {
    background-color: #1C254C;
    color: white;
    border: 1px solid #E1E0E0;
  }
  
// scroll
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7a7a33;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #7a7a7aaa;
  // background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}


// panel tabs css


.p-panel-profile {
  .p-panel-header {
    padding: 1rem;
    background: var(--page-background-color);
    // border: none;
    border-top: none;
    border-left: none;
    border-right: none;
    .p-panel-title {
        color: var(--primary-color-text) !important;
        font-size: 20px !important;
        padding-left: 4.5rem !important;
        font-weight: 500;
    }
}
.p-panel-content {
    background: var(--page-background-color);
    border: none;
}
}
.p-inputtext:not(.p-dropdown-label),
.p-password-input {
    width: 100%;
    height: 41.8px;
    border-radius: 8px;
    border: 1px solid #66666659 !important;
    color: var(--text-dim-color);
    padding: 0px 20px;
    font-size: 14px;
}

.p-inputtext.p-filled {
    font-family: "Product Sans medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color-text) !important;
}

.p-dropdown {
    width: 100%;
    height: 41.8px;
    border-radius: 8px;
    border: 1px solid #66666659 !important;
    color: var(--text-dim-color);
    padding: 0px 10px !important;
    align-items: center;

    .p-dropdown-label {
        font-family: "Product Sans medium", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: var(--primary-color-text);
    }

    .p-dropdown-trigger {
        position: relative;
        left: 10px !important;
    }
}
.dim-text {
    color: var(--text-dim-color) !important;
}

.primary-text {
    color: var(--primary-color-text);
}
.upload-button {
  .p-fileupload-choose {
      border-radius: 13px;
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      width: 150px;
      border-radius: 65px;
  }

  .p-icon.p-button-icon.p-button-icon-left {
      display: none;
  }
}
#removeBtn span {
  font-weight: 400;
  font-size: 15px;
}
.main-heading {
    font-family: "Product Sans", sans-serif;
    font-weight: 500 !important;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #2c2c2c;
}

.main-heading.font-bold {
    font-weight: 700 !important;
}
.main-page-layout{
padding-top: 0.25rem;
margin-left: 3rem;
margin-right: 3rem;
}

.custom-modal {
  .p-dialog-header {
      border-bottom: 0px !important;
      padding: 16px 16px 0px 16px !important;
  }

  .p-dialog-content {
      padding: 0px 0px 30px 0px !important;
  }
}

.upload-button {
  .p-fileupload-choose {
      border-radius: 13px;
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      width: 150px;
      border-radius: 65px;
  }

  .p-icon.p-button-icon.p-button-icon-left {
      display: none;
  }
}
.upload-link {
  .p-fileupload-choose {
      font-size: 1.125rem;
      font-weight: 400;
      background: none !important;
      border: none !important;
      color: var(--secondary-menu-color) !important;
      margin: 0 !important;
      padding: 0 !important;
  }
  .p-fileupload-choose:focus{
      outline: none !important;
      box-shadow: none !important;
  }

  .p-icon.p-button-icon.p-button-icon-left {
      display: none;
  }
}
.status-toast{
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
  display: none;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  display: none;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #3b82f6;
  display: none;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #71C341;
  border-width: 0px;
  color: #ffffff;
  font-size: 16px !important;
  font-weight: 400 ;
  font-family: 'Product Sans';
  width: 411px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 400;
}
}
// timezone dropdown design

.grouped-header {
  margin: 2px;
  border-bottom: 1px solid;
  padding: 5px;
  margin-bottom: 5px;

}
.computer-timezone:hover{
  background-color: #E9ECEF;
}
.p-dropdown-item-group {
  padding: 0px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2C2C2C;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  font-family: 'Product Sans';
  font-size: 14px;
  font-weight: 400;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.confirm-title{
  font-weight: 700;
  font-size: 24px;
  font-family: 'Product Sans';
  color: '#2C2C2C';
}
.confirm-para{
  font-weight: 400;
  font-size: 18px;
  font-family: 'Product Sans';
  color: '#2C2C2C';
}

.custom-loader {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 2500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-image {
      opacity: 70% !important;
  }
  height: 100vh;
  width: 100%;
}

.spinner {
  // margin: 0px auto 150px;
  width: 80px;
  text-align: center;
}

.spinner > div {
  width: 24px;
  height: 24px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.highcharts-markers {
  display: none !important; 
}
// .highcharts-series.highcharts-series-0.highcharts-map-series.highcharts-color-0.highcharts-tracker {
//   transform: scaleX(1.5); 
//   transform-origin: center; 
// }
 .highcharts-map-navigation {
  display: none !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: #696969 ;

}


.open-Hub{
 transition: transform 0.3s ease;
  .tri {
    position: relative;
    background: #107E5F;
    width: 23px;
    aspect-ratio: 1 / cos(30deg);
    -webkit-mask: 
      conic-gradient(from -30deg at 50% calc(200% - 2.7px), #000 60deg, #0000 0) 
      0 100% / 100% calc(100% - 2.7px) no-repeat, 
      radial-gradient(1.8px at 50% calc(3.6px), #000 98%, #0000 101%), 
      radial-gradient(1.8px, #000 98%, #0000 101%) space no-repeat 
      0 100% / calc(2 * tan(60deg) * 1.8px) calc(3.6px);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
  
  .tri.a {
    top: 3px;
  }
  
  .tri.b {
    position: absolute;
    top: 12.5px;
    left: -10px;
    transform: rotate(47deg);
  }
  
  .tri.c {
    position: absolute;
    top: 13.5px;
    left: 14px;
    transform: rotate(73deg);
  }
  
  .tri.d {
    position: absolute;
    top: 25px;
    left: -9px;
    transform: rotate(-20deg);
  }
  
  .tri.e {
    position: absolute;
    top: 24px;
    left: 9px;
    transform: rotate(18deg);
  }
  
}

.limited-hub{
  transition: transform 0.3s ease;
  .tri {
    position: relative;
    background: #238EF1;
    width: 23px;
    aspect-ratio: 1 / cos(30deg);
    -webkit-mask: 
      conic-gradient(from -30deg at 50% calc(200% - 2.7px), #000 60deg, #0000 0) 
      0 100% / 100% calc(100% - 2.7px) no-repeat, 
      radial-gradient(1.8px at 50% calc(3.6px), #000 98%, #0000 101%), 
      radial-gradient(1.8px, #000 98%, #0000 101%) space no-repeat 
      0 100% / calc(2 * tan(60deg) * 1.8px) calc(3.6px);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
  
  .tri.a {
    top: 3px;
  }
  
  .tri.b {
    position: absolute;
    top: 12.5px;
    left: -10px;
    transform: rotate(47deg);
  }
  
  .tri.c {
    position: absolute;
    top: 13.5px;
    left: 14px;
    transform: rotate(73deg);
  }
  
  .tri.d {
    position: absolute;
    top: 25px;
    left: -9px;
    transform: rotate(-20deg);
  }
  
  .tri.e {
    position: absolute;
    top: 24px;
    left: 9px;
    transform: rotate(18deg);
  }
  
}


.closed-hub{
  transition: transform 0.3s ease;
  .tri {
    position: relative;
    background: red;
    width: 23px;
    aspect-ratio: 1 / cos(30deg);
    -webkit-mask: 
      conic-gradient(from -30deg at 50% calc(200% - 2.7px), #000 60deg, #0000 0) 
      0 100% / 100% calc(100% - 2.7px) no-repeat, 
      radial-gradient(1.8px at 50% calc(3.6px), #000 98%, #0000 101%), 
      radial-gradient(1.8px, #000 98%, #0000 101%) space no-repeat 
      0 100% / calc(2 * tan(60deg) * 1.8px) calc(3.6px);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
  
  .tri.a {
    top: 3px;
  }
  
  .tri.b {
    position: absolute;
    top: 12.5px;
    left: -10px;
    transform: rotate(47deg);
  }
  
  .tri.c {
    position: absolute;
    top: 13.5px;
    left: 14px;
    transform: rotate(73deg);
  }
  
  .tri.d {
    position: absolute;
    top: 25px;
    left: -9px;
    transform: rotate(-20deg);
  }
  
  .tri.e {
    position: absolute;
    top: 24px;
    left: 9px;
    transform: rotate(18deg);
  }
  
}


.missing-hub{
  transition: transform 0.3s ease;
  .tri {
    position: relative;
    background: #808080;
    width: 23px;
    aspect-ratio: 1 / cos(30deg);
    -webkit-mask: 
      conic-gradient(from -30deg at 50% calc(200% - 2.7px), #000 60deg, #0000 0) 
      0 100% / 100% calc(100% - 2.7px) no-repeat, 
      radial-gradient(1.8px at 50% calc(3.6px), #000 98%, #0000 101%), 
      radial-gradient(1.8px, #000 98%, #0000 101%) space no-repeat 
      0 100% / calc(2 * tan(60deg) * 1.8px) calc(3.6px);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
  
  .tri.a {
    top: 3px;
  }
  
  .tri.b {
    position: absolute;
    top: 12.5px;
    left: -10px;
    transform: rotate(47deg);
  }
  
  .tri.c {
    position: absolute;
    top: 13.5px;
    left: 14px;
    transform: rotate(73deg);
  }
  
  .tri.d {
    position: absolute;
    top: 25px;
    left: -9px;
    transform: rotate(-20deg);
  }
  
  .tri.e {
    position: absolute;
    top: 24px;
    left: 9px;
    transform: rotate(18deg);
  }
}
.base-font span {
  font-family: 'Product Sans';
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #C8E6C9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}
.p-tooltip {
  box-shadow: none;
  width: 150px;

  .p-tooltip-text {
      background-color: #fff;
      border-radius: 8px;
      color: #000;
      box-shadow: 0px 6px 10px -2px #1B212C1F;
  }

  &.p-tooltip-right {
      .p-tooltip-arrow {
          font-size: 2rem;
          border-right: 5px solid #fff;
      }
  }

  &.p-tooltip-left {
      .p-tooltip-arrow {
          font-size: 2rem;
          border-left: 5px solid #fff;
      }
  }

  &.p-tooltip-bottom {
      .p-tooltip-arrow {
          font-size: 2rem;
          border-bottom: 5px solid #fff;
      }
  }
}
.primary-tooltip {
  width: auto !important;
  margin-top: 6px;

  .p-tooltip-text {
      background: var(--primary-color) !important;
      color: var(--tolltip-text-color) !important;
      padding: 0.75rem 0.75rem;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;

  }&.p-tooltip-bottom {
      .p-tooltip-arrow {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid var(--primary-color) !important;
      }
  }

}
.notification-view {
  .notification-tabview {

      .p-tabview-nav {
          padding: 0px 5px !important;
          background-color: transparent;
          border: none !important;

          .p-highlight {
              background-color: transparent;

              .p-tabview-nav-link {
                  background-color: transparent;
                  border-width: 0 0 5px 0 !important;
              }
          }

          .p-tabview-nav-link {
              background-color: transparent;
          }
      }

      .p-tabview-panels {
          height: calc(100vh - 230px);
          padding: 0px !important;
          border-radius: 8px;
      }

  }

  .notification-body {
      height: calc(100vh - 250px);

      .unread {
          background-color: #f3f4f6;

          hr {
              color: #E9E9E9;
              border: 1px solid #E9E9E9;
              padding: 0px !important;
              margin: 0px !important;
          }
      }

      .read {
          background-color: none;

          hr {
              color: #E9E9E9;
              border: 1px solid #E9E9E9;
              padding: 0px !important;
              margin: 0px !important;
          }
      }
  }
}
.status-grid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem
}
.map-data-label {
  transition: transform 0.3s ease;
}
.map-data-label:hover {
  transform: scale(1.3); 
}
.p-toast {
  z-index: 99999 !important; 
  position: fixed !important; 
}
.missing-hub:hover {
  transform: scale(1.3); 
}
.closed-hub:hover {
  transform: scale(1.3); 
}
.limited-hub:hover {
  transform: scale(1.3); 
}
.open-Hub:hover {
  transform: scale(1.3); 
}
.viewstations-btn{
  width: 119px;
  height: 33px;
  cursor: pointer;
  background-color: transparent !important;
  font-weight: 400;
  font-size: 12px !important;
  border-radius: 11.58px; 
  font-family: 'Product Sans';
  border: 1px solid #E1E0E0;
  color: #808080;
}
.viewstations-click{
  border: 1.5px solid #1C254C;
  color: #1C254C;
}
.status-modal .p-dialog-footer {
  padding: 0.5rem 1.5rem !important;
}

.p-dialog .p-resizable-handle{
  display: none;
}
.border-all{
  border: 2px solid #496FC0;
}
.border-open{
  border: 2px solid #107E5F
}
.border-limited{
  border: 2px solid#238EF1
}
.border-closed{
  border: 2px solid #FF2626
}
.border-missing{
  border: 2px solid #808080
}
.secondary-text {
  color: var(--secondary-menu-color);
}
.tertiary-text {
  color: var(--secondary-color);
}
.sidepanel-details{
  font-size: 12px;
  font-weight: 400;
  font-family: 'Product Sans';
  line-height: 14.56px;
  color: var(--primary-color);
  cursor: pointer;
}
.timeline-detail--modal {
  .p-dialog-header {
      border-bottom: 1px solid var(--border-color) !important;
      padding: 8px 20px !important;

      .p-dialog-title {
          font-weight: 400;
          font-size: 15px;
          color: #000000

      }
  }

  .p-dialog-content {
      padding: 20px !important;
  }
}
.timeline-dialog-label{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Product Sans';
  line-height: 22.4px;
  color: var(--primary-color-text);
}
.timeline-dialog-comment{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Product Sans';
  line-height: 21.84px;
  color:#808080;
}
.t-w-7{
  font-weight: 700;
}


.profile-container{
  background-color: #2C3662;
  // width: 167px;
  height: 44px;
  border-radius: 10px;

.profile-name{
  color: #FFFFFF;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Product Sans';
  line-height: 20px;
}

.profile-role{
  color: #F9F9F9;
  font-family: 'Product Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

}





.zoomed-top-title-font{
  font-size: 14px;
  font-weight: 400;
  font-family: 'Product Sans';
}

.zoomed-percent-font{
  font-size: 14px;
  font-weight: 700;
  font-family: 'Product Sans';
}
.statusdetail-label{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Product Sans';
  color: #696969;
  line-height: 19.41px;
  white-space: normal;            
  overflow-wrap: break-word;     
  word-break: break-all;            
  }
  
.custom-select-button .p-selectbutton .p-button {
  cursor: default; 
}
.timezone-label{
  font-size: 13px;
  font-weight: 400;
  font-family: 'Product Sans';
  color: #696969;
  line-height: 15.77px;
}
.inactive-legend{color: #C0C0C0;}
.active-legend{
  color: #37488E;
}
.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

