:root {
    --font-family: "Product Sans", sans-serif;
    --primary-color: #1c254c;
    --secondary-color: #e50515;
    --primary-menu-color: #2a3771;
    --secondary-menu-color: #37488e;
    --primary-color-text: #2c2c2c;
    --secondary-black: #293752;
    --primary-input: #d6e4f7;
    --secondary-text: #293752;
    --primary-border-color: #00000029;
    --border-color: #e1e0e0;
    --white-color: #ffffff;
    --red-menu-color: #ff4b5c;
    --sidebar-border-color: #f9f9f93d;
    --text-dim-color: #696969;
    --bg-color: #fafbff;
    --field-border-color: #00000024;
    --tolltip-text-color:#EFF0F6;
    --tolltip-customer-color:#056774;

    --high-severity-color: #ff2626;
    --medium-severity-color: #fca311;
    --low-severity-color: #0ED9A0;
    --average-severity-color: #3298f1;
    

    --on-track-color: linear-gradient(
        0deg,
        rgba(104, 184, 254, 0.8),
        rgba(104, 184, 254, 0.8)
    );
    --completed-color: linear-gradient(
        0deg,
        rgba(74, 226, 184, 0.6),
        rgba(74, 226, 184, 0.6)
    );
    --at-risk-color: linear-gradient(
        0deg,
        rgba(255, 38, 38, 0.6),
        rgba(255, 38, 38, 0.6)
    );
   
    --early-color: #3298f1;
    --ontime-color: #0ED9A0;
    --late-color: #ff2626;
    --tag-success: #28a74533;
    --tag-danger: #c80d0a33;
    --tag-warning: #fca31133;

    --tag-completed: #32985C14;
    --page-background-color: #FAFBFF;
    --table-row-border-color: #0000001A;
    --panel-border-color: #00000029;
    --hover-color : #1C254C0F;
    --default-doghnut-color:#b5b7b9;
    --doghnut-hover-color:#13426B;
    --ontime-hover-color: #33a3838d;

}
