.p-tabview-nav {
    border-top-left-radius: 8px;
    border: 1px solid #e1e0e0;
    border-top-right-radius: 8px;
    padding: 0 20px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    color: var(--secondary-menu-color) !important;
    border-width: 0 0 4px 0 !important;
    border: 3px solid var(--secondary-menu-color);
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #616161 !important;
    border: none;
}

.p-tabview .p-tabview-panels {
    min-height: 60vh;
    padding: 1.5rem 1rem;
    border: 0 0;
    border-width: 0 1px 1px 1px !important;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #e1e0e0;
}
